import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import withI18n from '../utils/hocs';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import ArticleCard from '../components/ArticleCard';

import ogImage from '../images/fb-image.png';
import ogImageEn from '../images/fb-image_en.png';

const BlogPage = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const data = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "blog/hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      caseStudyAscenzaLocalSitesImage: file(
        relativePath: { eq: "blog/ascenza-local-sites/card.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Layout className="blog-list">
      <SEO
        title={t('SEO.blog.title', 'Blog - Mosca Digital')}
        description={t('SEO.blog.description', 'Veja o que há de novo na Mosca Digital')}
        url={'https://moscadigital.pt' + (i18n.language === 'en' ? '/en' : '') + '/blog'}
        imageUrl={i18n.language === 'en' ? ogImageEn : ogImage}
      />
      <Hero
        bgImageFluid={data.heroImage.childImageSharp.fluid}
        title={t('blog.title', 'Blog')}
        subtitle={t('blog.description', 'Veja o que há de novo na Mosca Digital')}
      />
      <section className="posts-list">
        <div className="posts-row">
          <div className="container">
            <div className="blog-articles">
              <ArticleCard
                href="/blog/case-study-ascenza-local-sites"
                imageFluid={data.caseStudyAscenzaLocalSitesImage.childImageSharp.fluid}
                date={t('article_card.case_study_ascenza_local_sites.date', 'Setembro 26, 2020')}
                title={t(
                  'article_card.case_study_ascenza_local_sites.title',
                  'Caso de Estudo: Sites locais ASCENZA',
                )}
                body={t(
                  'article_card.case_study_ascenza_local_sites.description',
                  'Quatro websites. Uma base de código para todos construir, Uma migração para povoá-los; Um CMS para todos gerir e no Verão lançá-los.',
                )}
              ></ArticleCard>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default withI18n(BlogPage);
